import React from "react"

import { FaRegEnvelope, FaHashtag } from "react-icons/fa"
import { MdAlternateEmail } from "react-icons/md"
import { FiUpload, FiLogIn } from "react-icons/fi"

export const STATUS = [
  { label: "Approved by uploader", status: "approved" },
  { label: "Approved by third party", status: "approved-third-party-approve" },
  { label: "Pending by uploader", status: "pending" },
  { label: "Pending by third party", status: "pending-third-party-approve" },
  { label: "Not approved by uploader", status: "not-approved" },
  { label: "Not approved by third party", status: "not-approved-third-party" },
]

export const ASSET_STATUS = {
  created: "created",
  approved: "approved",
  pending: "pending",
  notApproved: "not-approved",
}

export const ASSET_RIGHT_STATUS = {
  created: "created",
  pending: "pending",
  approved: "approved",
  notApproved: "not-approved",
}

export const CAMPAIGN_STATUS = {
  active: "active",
  inactive: "inactive",
  deleting: "deleting",
}

export const ASSET_STATE = {
  unreviewed: "unreviewed",
  pending: "pending",
  approved: "approved",
  rejected: "rejected",
  deleted: "deleted",
}

export const CAMPAIGN_TYPE = {
  upload: {
    type: "upload_2",
    icon: (
      <FiUpload
        style={{
          strokeWidth: "3px",
        }}
      />
    ),
  },
  igHashtag: {
    type: "hashtag",
    icon: <FaHashtag />,
  },
  igMentions: {
    type: "mentions",
    icon: <MdAlternateEmail />,
  },
  email: {
    type: "email",
    icon: <FaRegEnvelope />,
  },
  landingpage: "landingpage",
  import: {
    type: "import",
    icon: (
      <FiLogIn
        style={{
          strokeWidth: "3px",
          transform: "scale(-1,1)",
        }}
      />
    ),
  },
}

export const MEDIA_TYPE = {
  IMAGE: "IMAGE",
  VIDEO: "VIDEO",
  AUDIO: "AUDIO",
  TEXT: "TEXT",
}

export const IMG_SIZES = {
  thumb: 200,
  preView: 500,
}

export const DOWNLOAD_FILE_STATES = {
  created: "created",
  processing: "processing",
  finished: "finished",
  error: "error",
}
export const PUBLISHING_JOB_TYPES = {
  instagram: "instagram",
  facebook: "facebook",
  youtube: "youtube",
}

export const PUBLISHING_JOB_STATUS = {
  inProgress: "IN_PROGRESS",
  published: "PUBLISHED",
  expired: "EXPIRED",
  error: "ERROR",
}

export const RIGHT_REQUEST_STATUS = {
  created: "CREATED",
  error: "ERROR",
  completed: "COMPLETED",
}

export const RIGHTS_REQUEST_TYPE = {
  creator: "CREATOR",
  thirdParty: "THIRD_PARTY",
}

export const APPROVAL_CHANNEL = {
  approvalPage: "APPROVAL_PAGE",
  consentHashtag: "CONSENT_HASHTAG",
}

export const VERIFICATION_METHOD = {
  none: "NONE",
  instagram: "INSTAGRAM",
}

export const RIGHTS_REQUEST_APPROVAL_STATUS = {
  created: "created",
  pending: "pending",
  approved: "approved",
  notApproved: "not-approved",
}
