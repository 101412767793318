import React, { useState, useEffect } from "react"

import * as S from "../FormStyles"
import ReelerProgress from "./Progress/ReelerProgress"
import Loader from "../../commons/loader/Loader"

const SubmitButtonWidget = ({
  design,
  field,
  successMessage,
  uploadSuccess,
  totalBytes,
  bytesTransferred,
  uploadError,
  saving,
  disabled,
}) => {
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    if (totalBytes && bytesTransferred) {
      const totaltBytesTransferred = bytesTransferred.reduce(
        (accumulator, bytesTransferred) => accumulator + bytesTransferred,
        0
      )

      setProgress(Math.round((totaltBytesTransferred / totalBytes) * 100) / 100)
    }
  }, [bytesTransferred, totalBytes])

  return (
    <S.SubmitContainer>
      {uploadError ? (
        <S.ErrorMessageContainer>
          <S.ErrorMessage>{uploadError}</S.ErrorMessage>
        </S.ErrorMessageContainer>
      ) : uploadSuccess ? (
        <S.SuccessMessageContainer>
          <S.SuccessMessage>{successMessage}</S.SuccessMessage>
        </S.SuccessMessageContainer>
      ) : progress > 0 ? (
        <div className="d-flex flex-column my-3">
          <S.ProgressBarContainer>
            {progress === 1 ? (
              <>
                <Loader />
                <strong style={{ marginTop: "0.5rem" }}>
                  Finalizing the submission, please wait...
                </strong>
              </>
            ) : (
              <>
                <ReelerProgress progress={progress} />
                <strong style={{ marginTop: "0.5rem" }}>
                  Uploading content, please wait...
                </strong>
              </>
            )}
            <small style={{ marginTop: "0.5rem" }}>
              Please stay on this page until the process is complete.
            </small>
          </S.ProgressBarContainer>
        </div>
      ) : null}
      <S.SubmitButton
        $btnSize={design?.submitBtnSize ? design.submitBtnSize : "md"}
        $btnColor={
          design?.submitBtnColor
            ? design?.submitBtnColor
            : field?.btnColor
            ? field?.btnColor
            : "#007bff"
        }
        $btnFontColor={
          design?.submitBtnFontColor
            ? design?.submitBtnFontColor
            : field?.btnFontColor
            ? field?.btnFontColor
            : "#ffffff"
        }
        type="submit"
        disabled={disabled}
        saving={saving}
      >
        {saving
          ? "Uploading...Please wait"
          : field?.btnText
          ? field?.btnText
          : "Submit"}
      </S.SubmitButton>
    </S.SubmitContainer>
  )
}
export default SubmitButtonWidget
