import React from "react"

import * as S from "./LoadingScreen-styles"
import Loader from "../loader/Loader"

export default function LoadingScreen() {
  return (
    <S.LoadingScreen>
      <Loader />
    </S.LoadingScreen>
  )
}
