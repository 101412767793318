import React from "react"

const PublicNotFound = () => {
  return (
    <div className="pt-5 d-flex flex-column justify-content-center align-items-center">
      <h1>Page not found</h1>
      <p style={{ width: "400px" }}>
        Whooops! We don't have the page you're looking for...
      </p>
    </div>
  )
}

export default PublicNotFound
