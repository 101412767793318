import styled from "styled-components"

export const TopRight = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;
`

export const CampaignType = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  color: #fff;
  padding: 0.2rem;
  margin: 0.3rem;
  border-radius: 5px;
`
export const FeedWidgetContainer = styled.div`
  width: 100%;
`
