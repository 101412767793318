import React from "react"
import * as S from "../FormStyles"

export default function FormFooter({ campaignId }) {
  return (
    <S.PowerdByContainer>
      <S.PowerdByText>Powered by</S.PowerdByText>

      <S.PowerdByLogo
        target="_blank"
        rel="noopener noreferrer"
        href={`https://www.reelertech.com/?utm_source=app&utm_medium=appform&utm_campaign=${campaignId}`}
      >
        <S.Logo
          src={`${process.env.REACT_APP_WIDGET_URL}/assets/images/ugc-user-generated-content-reeler.png`}
          alt="ugc-user-generated-content-reeler"
        />
      </S.PowerdByLogo>
    </S.PowerdByContainer>
  )
}
