import React from "react"
import * as S from "./styles"

export default function ReelerProgress({ progress }) {
  return (
    <S.ProgressBar max={100} value={progress * 100}>
      <S.ProgressText>{`${Math.round(progress * 100)}%`}</S.ProgressText>
    </S.ProgressBar>
  )
}
