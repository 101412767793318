import React from "react"
import { ImageContainer } from "../FormStyles"
export default function ImageWidget({ field }) {
  return (
    <ImageContainer $align={field?.align}>
      <img
        alt=""
        style={{
          width: `${field?.width}%`,
          margin: `${field?.margin}px`,
          padding: `${field?.padding}px`,
        }}
        src={field?.image_url}
      />
    </ImageContainer>
  )
}
