export const FORM_DEFAULT_SETTINGS = {
  formBackground: "transparent",
  fontFamily: "inherit",
  titleColor: "#212529",
  descriptionColor: "#6c757d",
  fieldFocusColor: "#3c3c3c",
  h1Color: "#000000",
  h1Size: "32",
  h2Color: "#000000",
  h2Size: "20",
  pColor: "#212529",
  pSize: "20",
  fileBtnFontColor: "#ffffff",
  fileBtnColor: "#6c757d",
  fileBtnBorderColor: "#6c757d",
  fileBtnSize: "md",
  submitBtnFontColor: "#ffffff",
  submitBtnColor: "#000000",
  submitBtnBorderColor: "#000000",
  submitBtnSize: "md",
  termsColor: "#212529",
}
