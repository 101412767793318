import React from "react"

const InputCounter = ({ currentChars, maxChars }) => {
  return (
    <div>
      {currentChars < maxChars ? (
        <small>
          {currentChars}/{maxChars}
        </small>
      ) : (
        <small className="text-danger">
          {currentChars}/{maxChars}
        </small>
      )}
    </div>
  )
}

export default InputCounter
