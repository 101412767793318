import axios from "axios"

export const fetchFormData = async campaignId => {
  const url = `${process.env.REACT_APP_FIREBASE_API_URL}/form/${campaignId}`

  const { data } = await axios({
    url: url,
    method: "get",
  })
  return data
}
