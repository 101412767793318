import "regenerator-runtime/runtime"
import React from "react"
//import "bootstrap/dist/css/bootstrap.min.css"
import "./index.css"
import App from "./App"
import { createRoot } from "react-dom/client"

const widgetDiv = document.querySelector("#reeler-widget")

if (widgetDiv) {
  const widget = createRoot(widgetDiv)
  widget.render(
    <React.StrictMode>
      <App
        feedId={widgetDiv?.dataset?.feed}
        feedVersion={widgetDiv?.dataset?.feedVersion}
        campaignId={widgetDiv?.dataset?.campaign}
      />
    </React.StrictMode>
  )
}

const root = createRoot(document.getElementById("root"))
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
