import React from "react"
// 3rd-party libraries

import * as S from "../FormStyles"

export default function FileWidget({
  fieldId,
  field,
  index,
  refArray,
  disabled = true,
  handleUpload,
  design,
}) {
  return (
    <S.WidgetContainer>
      {field.title ? (
        <S.ColoredLabel htmlFor={fieldId} $design={design}>
          {field.title}
        </S.ColoredLabel>
      ) : null}

      <div>
        <input
          ref={el =>
            (refArray.current = { ...refArray.current, [fieldId]: el })
          }
          type="file"
          onChange={handleUpload}
          disabled={disabled}
          required={field.required}
          id={fieldId}
          multiple={
            field &&
            field.maxNoFilesPerSubmission &&
            field.maxNoFilesPerSubmission > 1
              ? true
              : false
          }
        />
      </div>

      {field.description ? (
        <S.ColoredFormText $design={design}>
          {field.description}
        </S.ColoredFormText>
      ) : null}
    </S.WidgetContainer>
  )
}
