import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import { fetchFeedData } from "../../services/FeedService"
import LoadingScreen from "../../components/commons/LoadingScreen"
import RenderFeed from "../../components/feed/render-feed/RenderFeed"

export default function IFrameFeed() {
  const { feedId } = useParams()
  const [feed, setFeed] = useState()
  const [assets, setAssets] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)
    fetchFeedData(feedId).then(response => {
      setFeed(response.feed)
      setAssets(response.assets)

      setIsLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) return <LoadingScreen />

  return feed && assets ? <RenderFeed feed={feed} assets={assets} /> : null
}
