/**
 * Get asset media type
 *
 * @param fileName String
 
 * @return String
 */

const IMAGE = ["jpg", "jpeg", "tiff", "tif", "png", "bmp"]
const VIDEO = ["mov", "mp4"]
const AUDIO = ["mp3", "wav", "ogg", "m4a", "aif", "aiff"]

export function getAssetMediaType(fileName) {
  let format = fileName.split(".").pop().toLowerCase()

  if (IMAGE.includes(format)) {
    return "IMAGE"
  } else if (VIDEO.includes(format)) {
    return "VIDEO"
  } else if (AUDIO.includes(format)) {
    return "AUDIO"
  } else {
    return "NOT_SUPPORTED"
  }
}
