import axios from "axios"

export const fetchFeedData = async feedId => {
  let url = `${process.env.REACT_APP_FIREBASE_API_URL}/feed/${feedId}`

  const { data } = await axios({
    url: url,
    method: "get",
  })
  return data
}
