import React, { useEffect, useState } from "react"
import RenderFeed from "./render-feed/RenderFeed"
import { fetchFeedData } from "../../services/FeedService"
import LoadingScreen from "../commons/LoadingScreen"
import PublicNotFound from "../commons/NotFound/PublicNotFound"

export default function FeedWidget({ feedId, feedVersion }) {
  const [feed, setFeed] = useState()
  const [assets, setAssets] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    fetchFeedData(feedId).then(response => {
      setFeed(response.feed)
      setAssets(response.assets)

      setIsLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) return <LoadingScreen />

  return feed && assets ? (
    <RenderFeed feed={feed} assets={assets} />
  ) : (
    <PublicNotFound />
  )
}
