import styled from "styled-components"

export const PublicFormContainer = styled.section`
  padding: 0 20%;
  background-color: ${({ design }) =>
    design?.formBackground ? design.formBackground : "#fff"};
  font-family: ${({ design }) =>
    design?.fontFamily !== "inherit"
      ? design.fontFamily
      : '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif'};

  h1 {
    font-size: ${({ design }) =>
      design?.h1Size ? `${design.h1Size}px` : "32px"};
    color: ${({ design }) => (design?.h1Color ? design.h1Color : null)};
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
  }

  h2 {
    font-size: ${({ design }) =>
      design?.h2Size ? `${design.h2Size}px` : null};
    color: ${({ design }) => (design?.h2Color ? design.h2Color : null)};
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
  }

  p {
    font-size: ${({ design }) => (design?.pSize ? `${design.pSize}px` : null)};
    color: ${({ design }) => (design?.pColor ? design.pColor : null)};
    margin-top: 0;
    margin-bottom: 1rem;
    span {
      font-style: italic;
    }
  }

  @media (max-width: 576px) {
    padding: 15px 0;
  }
`
