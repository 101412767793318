import React from "react"
// 3rd-party libraries
import parse from "html-react-parser"

import * as S from "../FormStyles"

export default function TermsWidget({
  design,
  fieldId,
  field,
  refArray,
  disabled = false,
}) {
  return (
    <S.WidgetContainer $marginBottom={"0 !important"}>
      <S.MultiplechoiceOptionContainer>
        <S.MultiplechoiceOptionInput
          ref={el =>
            (refArray.current = { ...refArray.current, [fieldId]: el })
          }
          required={field?.required}
          className="form-check-input"
          id={fieldId}
          name={fieldId}
          type="checkbox"
          disabled={disabled}
        />

        <S.MultiplechoiceOptionLabel htmlFor={fieldId}>
          <small
            style={{
              color: design?.termsColor,
              fontSize: "12px",
            }}
          >
            {field.required ? <S.RequiredMark>* </S.RequiredMark> : null}
            {parse(field.terms)}
          </small>
        </S.MultiplechoiceOptionLabel>
      </S.MultiplechoiceOptionContainer>
    </S.WidgetContainer>
  )
}
