import React, { useEffect, useState } from "react"

// Reeler components
import PublicNotFound from "../commons/NotFound/PublicNotFound"
import LoadingScreen from "../commons/LoadingScreen"

import { fetchFormData } from "../../services/FormService"
import FormWidget from "./FormWidget"

export default function Form({ campaignId }) {
  // States
  const [campaign, setCampaign] = useState()
  const [loading, setLoading] = useState(true)
  const [isActive, setIsActive] = useState(true)

  useEffect(() => {
    setLoading(true)
    fetchFormData(campaignId).then(response => {
      setCampaign(response)

      // Check if campaign is active
      if (response?.status === "inactive") {
        setIsActive(false)
      }

      setLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading && !campaign) return <LoadingScreen />

  return campaign ? (
    <FormWidget
      campaignId={campaignId}
      campaign={campaign}
      isActive={isActive}
    />
  ) : (
    <PublicNotFound />
  )
}
