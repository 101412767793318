import React, { useState, useEffect } from "react"

import { useParams } from "react-router-dom"
import FormWidget from "../../components/form/FormWidget"

import * as S from "./PublicForm.styles"
import PublicNotFound from "../../components/commons/NotFound/PublicNotFound"
import { fetchFormData } from "../../services/FormService"
import LoadingScreen from "../../components/commons/LoadingScreen"

export default function PublicForm() {
  const { campaignId } = useParams()
  const [campaign, setCampaign] = useState()
  const [loading, setLoading] = useState(true)
  const [isActive, setIsActive] = useState(true)

  useEffect(() => {
    setLoading(true)

    fetchFormData(campaignId).then(response => {
      setCampaign(response)
      // Check if campaign is active
      if (response?.status === "inactive") {
        setIsActive(false)
      }
      // Set doc body background
      if (response?.formSchema?.design?.formBackground)
        document.body.style.backgroundColor =
          response?.formSchema?.design?.formBackground
      setLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) return <LoadingScreen />

  return campaign ? (
    <S.PublicFormContainer design={campaign?.formSchema?.design}>
      <FormWidget
        campaignId={campaignId}
        campaign={campaign}
        isActive={isActive}
      />
    </S.PublicFormContainer>
  ) : (
    <PublicNotFound />
  )
}
